@include media-breakpoint-up(lg) {
  .dropdown-menu .dropdown-menu {
    top: 0;
    left: 100%;
    margin-top: 0;
  }
}

.logo {
  @include media-breakpoint-up(md) {
    width: 160px;
  }

  @include media-breakpoint-down(sm) {
    width: 130px;
  }
}

.main-content > :first-child:not(nav):not(.nav) {
  margin-top: map-get($spacers, 4);
  margin-bottomtop: map-get($spacers, 4);
}


.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99999;
  display: none;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, .7);
}

.loadable.loading .loading-overlay {
  display: flex;
}


label.required {
  &::after {
    content: '*';
    display: inline-block;
    padding-left: 4px;
    color: $danger;
  }
}

.btn-icon {
  padding-right: 5px;
  padding-left: 5px;
  //font-size: $font-size-lg;
}

a:hover, a:focus {
  border: 1px dotted $link-hover-color;
}

footer a {
  // Force a higher contrast ratio for footer links w/ .bg-dark
  color: $link-color-alt;
}

footer a:hover {
  // Force a higher contrast ratio for footer links w/ .bg-dark
  color: $link-hover-color-alt;
}

// Helpers
.link-text-light a, .link-text-light li a {
  color: $link-color-alt;
}

.link-text-light a:hover, .link-text-light li a:hover {
  color: $link-hover-color-alt;
}

.navbar-nav .nav-link {
  // Force a higher contrast ratio for main menu links w/ .bg-light
  color: $nav-color;
}

.navbar-expand-lg .navbar-nav .nav-item:first-child .nav-link {
  padding-left: 0;
}

.divider {
  padding: 0 0.5rem;
  display: inline-block;
  color: #dee2e6;
  content: "/";
}
